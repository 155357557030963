import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import cn from 'clsx';
import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStartedCenter from 'components/GetStartedCenter';
// import DemoModal from 'components/DemoModal';
// import { Main } from 'styles/features';
// import BugTrack from 'styles/bug-tracking';
import NewFaq from '../../components/NewFaq/NewFaq';
import FeatureContainer from '../../components/FeatureContainer';
import { signUp } from '../../utils/contentProvider/function';
// import LoginWithGoogle from '../../components/LoginWithGoogle';
import TrustedBy from '../../components/TrustedBy';
import TestimonialsSlider from '../../components/TestimonialsSlider/TestimonialsSlider';
import 'styles/bug-tracking.scss';
import 'styles/features.scss';
import LoginWithGoogle from '../../components/LoginWithGoogle';
const CommentMode = ({ location }) => {
  const [showVideoModal, setVideoModal] = useState(false);

  function toggleVideoModal() {
    setVideoModal(!showVideoModal);
  }

  function clickHandler(e) {
    if (e.target.id === 'demo-modal') setVideoModal(false);
  }

  useEffect(() => {
    window.addEventListener('click', clickHandler);

    return () => window.removeEventListener('click', clickHandler);
  }, [clickHandler]);

  useEffect(() => {
    document.body.style.overflow = showVideoModal ? 'hidden' : 'auto';
  }, [showVideoModal]);

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', (e) => {
        e.preventDefault();

        document.querySelector(anchor.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
        });
      });
    });
  }, []);

  return (
    <Layout location={location}>
      {({ toggleSignup }) => (
        <>
          <Meta
            title="Website Commenting Tool to Comment on Any Website - Ruttl"
            description="Best Website Commenting Tool for adding & collecting comments on live websites and web pages. Works with all browsers. Trusted by over 15,000 companies."
            url="https://ruttl.com/website-commenting-tool/"
          />
          <div
            id="demo-modal"
            className={cn(['demo-modal', { show: showVideoModal }])}>
            <button
              type="button"
              aria-label="close"
              className="button-unstyled"
              onClick={toggleVideoModal}>
              <img src="/assets/img/close.svg" alt="close" title="close" />
            </button>
            {showVideoModal && (
              <iframe
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/yMyZpJqWRso"
                frameBorder="0"
                title="ruttl demo"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </div>
          <main className="features-styled-main">
            <section className="features-hero comment-on-website">
              <div className="container">
                <h1 className="comment-on-website-heading">
                  The Fastest Way To
                  <br />
                  Comment On Websites
                </h1>
                <p>
                  Comment on any live website, webpage or static image with
                  Ruttl. The easiest way to start collecting or sharing feedback
                  through comments, with clients and teammates.
                </p>

                <div className="hero-review-block">
                  <div className="review-stars">
                    <img src="/assets/img/stars.svg" alt="Star ratings" />
                    <p>Based on 100+ reviews on</p>
                  </div>

                  <div className="review-logos">
                    <img
                      src="/assets/img/logo/g2-crowd-full.png"
                      alt="G2 Crowd Logo"
                    />
                    <img
                      src="/assets/img/logo/capterra-full.png"
                      alt="Capterra Logo"
                    />
                    <img
                      src="/assets/img/logo/get-app-full.png"
                      alt="Get App Logo"
                    />
                  </div>
                </div>
                <div
                  className="reveal-1 button-container"
                  style={{ margin: '30px 0' }}>
                  <a href="https://web.ruttl.com" className="button">
                    Get started for free
                  </a>
                  <LoginWithGoogle
                    white
                    styleProp={{
                      padding: '5px 35px',
                    }}
                  />
                </div>
              </div>
            </section>
            <TrustedBy />
            <div className="styled-bug-tracking greyBg">
              <div className="container">
                <h2 className="center" id="view-demo">
                  How it works
                </h2>
                <div className="feature-banner">
                  <button
                    type="button"
                    aria-label="play"
                    className="button button-transparent"
                    onClick={toggleVideoModal}>
                    <img
                      src="/assets/img/comment-mode.png"
                      alt="youtube video banner for comment on website feature of ruttl"
                      title="youtube video banner for comment on website feature of ruttl"
                      className="banner-thumb"
                    />
                    <img
                      className="play-button"
                      src="/assets/img/play.svg"
                      alt="play icon designed by brucira"
                      title="play icon designed by brucira"
                    />
                  </button>
                </div>
                <h2 className="center" id="view-demo">
                  Start adding comments in just 30 seconds
                </h2>
                <div className="flex-2 flex flex-wrap align-items-stretch">
                  <div className="wrapper">
                    <div className="card-single">
                      <div className="card-block">
                        <div>
                          <img
                            loading="lazy"
                            src="/assets/img/pot.svg"
                            alt="flower pot icon"
                            title="flower pot icon"
                          />
                        </div>
                        <h3>1. Create a project</h3>
                        <p>
                          Log into your ruttl account and create a new project.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="wrapper">
                    <div className="card-single">
                      <div className="card-block">
                        <div>
                          <img
                            loading="lazy"
                            src="/assets/img/open-webpage.png"
                            alt="security icon"
                            title="security icon"
                          />
                        </div>
                        <h3>2. Add Any URL</h3>
                        <p>
                          Just copy and paste any website URL during the project
                          creation.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="wrapper">
                    <div className="card-single">
                      <div className="card-block">
                        <div>
                          <img
                            loading="lazy"
                            src="/assets/img/bug-tracking-maintainance.svg"
                            alt="spanner inside mechanical gear icon"
                            title="spanner inside mechanical gear icon"
                          />
                        </div>
                        <h3>3. Start Commenting</h3>
                        <p>
                          Click on any part of the website and start leaving
                          comments on the live website.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section features-main" id="features">
              <div className="container">
                <h2 className="center" style={{ marginTop: '40px' }}>
                  Website Commenting Features
                </h2>
                <FeatureContainer>
                  <div className="feature-set-single" id="comment-live-website">
                    <div className="flex flex-wrap align-items-center">
                      <div className="feature-img">
                        <img
                          loading="lazy"
                          src="/assets/img/leave-comments-website.jpg"
                          alt="illustration of leaving comment on live website"
                          title="illustration of leaving comment on live website"
                        />
                      </div>
                      <div
                        className="feature-txt"
                        style={{ transform: ' translateY(-40px)' }}>
                        <h3
                          className="feature-head"
                          style={{ maxWidth: '420px' }}>
                          Leave comments on live webpages &amp; static images
                        </h3>
                        <p>
                          Share feedback through real-time comments and review
                          them any time.
                        </p>
                        <button
                          type="button"
                          onClick={() => signUp(toggleSignup)}
                          className="button">
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </FeatureContainer>
                <FeatureContainer>
                  <div className="feature-set-single" id="edit-live-website">
                    <div className="flex flex-wrap align-items-center">
                      <div className="feature-img">
                        <div className="feature-img-bg">
                          <img
                            loading="lazy"
                            src="/assets/img/tag-teammates-comments.png"
                            alt="illustration of tag your teammates to assign comments as tasks"
                            title="illustration of tag your teammates to assign comments as tasks"
                          />
                        </div>
                      </div>
                      <div
                        className="feature-txt"
                        style={{ transform: ' translateY(-10px)' }}>
                        <h3
                          className="feature-head"
                          style={{ maxWidth: '420px' }}>
                          Tag your teammates to assign comments as tasks
                        </h3>
                        <p>
                          Collaborate with your colleagues and assign them
                          deadlines, tasks on the go.
                        </p>
                        <button
                          type="button"
                          onClick={() => signUp(toggleSignup)}
                          className="button">
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </FeatureContainer>
                <FeatureContainer>
                  <div className="feature-set-single" id="edit-website-content">
                    <div className="flex flex-wrap align-items-center">
                      <div className="feature-img">
                        <div className="feature-img-bg">
                          <img
                            loading="lazy"
                            src="/assets/img/leave-video-comments.png"
                            alt="illustration of video comments"
                            title="illustration of video comments"
                          />
                        </div>
                      </div>
                      <div
                        className="feature-txt"
                        style={{ transform: ' translateY(0px)' }}>
                        <h3 className="feature-head">Leave video comments</h3>
                        <p>
                          Provide specific feedback through visual comments and
                          simplify web review.
                        </p>
                        <button
                          type="button"
                          onClick={() => signUp(toggleSignup)}
                          className="button">
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </FeatureContainer>
                <FeatureContainer>
                  <div className="feature-set-single" id="client-commenting">
                    <div className="flex flex-wrap align-items-center">
                      <div className="feature-img">
                        <video
                          src="/assets/img/guest-commenting.mp4"
                          autoPlay
                          muted
                          loop
                          playsInline></video>
                      </div>
                      <div
                        className="feature-txt"
                        style={{ transform: ' translateY(0px)' }}>
                        <h3 className="feature-head">
                          Guest commenting - Clients can comment without logging
                          in
                        </h3>
                        <p>
                          Simply send a shareable link to your clients, they
                          could comment without the need of signing up.
                        </p>
                        <button
                          type="button"
                          onClick={() => signUp(toggleSignup)}
                          className="button">
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </FeatureContainer>
                <FeatureContainer>
                  <div className="feature-set-single" id="static-image-review">
                    <div className="flex flex-wrap align-items-center">
                      <div className="feature-img">
                        <div className="feature-img-bg">
                          <img
                            loading="lazy"
                            src="/assets/img/comments-resolved.png"
                            alt="illustration of comments resolved after done"
                            title="illustration of comments resolved after done"
                          />
                        </div>
                      </div>
                      <div
                        className="feature-txt"
                        style={{ transform: ' translateY(0px)' }}>
                        <h3 className="feature-head">
                          Comments can be resolved once done
                        </h3>
                        <p>
                          Review all the comments that were assigned. Your
                          teammates can mark them done once the tasks are
                          completed.
                        </p>
                        <button
                          type="button"
                          onClick={() => signUp(toggleSignup)}
                          className="button">
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </FeatureContainer>
                <FeatureContainer>
                  <div className="feature-set-single" id="inspect-css">
                    <div className="flex flex-wrap align-items-center">
                      <div className="feature-img">
                        <video
                          src="/assets/img/responsive.mp4"
                          autoPlay
                          muted
                          loop
                          playsInline></video>
                      </div>
                      <div
                        className="feature-txt"
                        style={{ transform: ' translateY(0px)' }}>
                        <h3 className="feature-head">Review mobile web</h3>
                        <p>
                          Know how the mobile web version appears and make
                          iterations by inspecting CSS whenever you like.
                        </p>
                        <button
                          type="button"
                          onClick={() => signUp(toggleSignup)}
                          className="button">
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </FeatureContainer>
              </div>
            </div>

            <div className="feature-get-started">
              <GetStartedCenter toggleSignup={toggleSignup} />
            </div>
            <TestimonialsSlider />

            <section className="usecases-main" id="usecases">
              <div className="container">
                <h2 className="heading" style={{ marginBottom: '25px' }}>
                  ruttl solves a lot of usecases
                </h2>
                {/* <p>
                We built ruttl to be the one-stop solution for your web design
                needs. Here’s a look at the use cases ruttl is perfect for:
              </p> */}

                <div className="flex flex-wrap">
                  <div className="usecase-block">
                    <div className="usecase-single" id="usecase-designer">
                      <img
                        src="/assets/img/designer.png"
                        alt="ruttl ui ux designer avatar"
                        title="ruttl ui ux designer avatar"
                      />
                      <h3>
                        <span className="blue-text">UI/UX Designer</span>
                        &nbsp;— Get your designs coded pixel perfectly
                      </h3>
                      <p>
                        As a designer, it’s really important that your design
                        and code match perfectly.&nbsp;
                        <Link to="/">ruttl</Link>
                        &nbsp;allows you to edit the staging link so you can
                        share the exact values with the developers to create
                        pixel perfect designs.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Free Trial
                      </button>
                    </div>
                  </div>

                  <div className="usecase-block">
                    <div className="usecase-single" id="usecase-developer">
                      <img
                        src="/assets/img/developer.png"
                        alt="ruttl developer avatar"
                        title="ruttl developer avatar"
                      />
                      <h3>
                        <span className="blue-text">Developers</span>
                        &nbsp;— Reduce coordination time by collecting exact CSS
                        values
                      </h3>
                      <p>
                        As a developer, you get exact values of the changes
                        needed to be done on the webpage. These changes can be
                        found under the Activity Tab. You can just copy and
                        paste the same on your main source
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Free Trial
                      </button>
                    </div>
                  </div>
                  <div className="usecase-block">
                    <div className="usecase-single">
                      <img
                        src="/assets/img/client.png"
                        alt="ruttl clients avatar"
                        title="ruttl clients avatar"
                      />
                      <h3>
                        <span className="blue-text">Clients</span>
                        &nbsp;— Give timely feedback, review changes, and see
                        your ideas come to life
                      </h3>
                      <p>
                        Clients are included in every step of the project with
                        ruttl. Through ruttl&apos;s Preview mode, clients can
                        make sure the designs align with their vision and switch
                        to Comment mode to offer clear &amp; contextual feedback
                        for any changes they&apos;d like.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Free Trial
                      </button>
                    </div>
                  </div>
                  <div className="usecase-block">
                    <div
                      className="usecase-single"
                      id="usecase-project-manager">
                      <img
                        src="/assets/img/project-manager.png"
                        alt="ruttl project manager avatar"
                        title="ruttl project manager avatar"
                      />
                      <h3>
                        <span className="blue-text">Project managers</span>
                        &nbsp;— Manage multiple web design projects easily with
                        a bird&apos;s eye view of every single activity
                      </h3>
                      <p>
                        With ruttl, project managers can clearly see the
                        progress on a project. All comments and suggestions are
                        in a single spot to guide changes, resolve pain points,
                        and ensure that the project is moving forward at the
                        right pace.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Free Trial
                      </button>
                    </div>
                  </div>
                  <div className="usecase-block">
                    <div className="usecase-single" id="usecase-content-writer">
                      <img
                        src="/assets/img/content-writer.png"
                        alt="ruttl content writer avatar"
                        title="ruttl content writer avatar"
                      />
                      <h3>
                        <span className="blue-text">Content Writers</span>
                        &nbsp; — Visualize how the content looks on the website
                      </h3>
                      <p>
                        As a content writer, you no longer have to use word
                        documents in order to send website copy. Write the
                        content directly on the live website and visualize how
                        it looks with the design. Word limits are a problem no
                        longer!
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Free Trial
                      </button>
                    </div>
                  </div>
                  <div className="usecase-block">
                    <div className="usecase-single" id="usecase-agency">
                      <img
                        src="/assets/img/agencies.png"
                        alt="ruttl agencies avatar"
                        title="ruttl agencies avatar"
                      />
                      <h3>
                        <span className="blue-text">Agencies</span>
                        &nbsp;— Work efficiently on multiple project and never
                        miss a deadline
                      </h3>
                      <p>
                        Agencies can work on multiple web projects seamlessly
                        and invite clients to review and provide feedback. This
                        saves a lot of time and money by reducing the
                        coordination hours between developers and designers.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Free Trial
                      </button>
                    </div>
                  </div>
                  {/*  
                <div className="usecase-block">
                  <div className="usecase-single" id="usecase-product-team">
                    <img
                      src="/assets/img/product-team.png"
                      alt="ruttl avatar for product teams"
                    />

                    <h3>
                      <span className="blue-text">Product teams</span>
                      &nbsp; — Boost intra-team and inter-team collaboration to
                      release new products, features, and more
                    </h3>
                    <p>
                      Not just websites, ruttl also allows you to test internal
                      tools, admin panels, and all types of web apps. Developers and
                      designers can collaborate effectively to get to perfect
                      results.
                    </p>
                    <a className="button" href="https://web.ruttl.com/signup">
                      Start Free Trial
                    </a>
                  </div>
                </div>
                <div className="usecase-block">
                  <div className="usecase-single" id="usecase-marketing-team">
                    <img
                      src="/assets/img/marketing-team.png"
                      alt="ruttl avatar for marketing teams"
                    />

                    <h3>
                      <span className="blue-text">Marketing teams</span>
                      &nbsp;— Reduce dependency on designers and developers; edit
                      websites without coding knowledge
                    </h3>
                    <p>
                      Try on different banners on your website or change the content
                      as you need without depending on designers and developers.
                    </p>
                    <a className="button" href="https://web.ruttl.com/signup">
                      Start Free Trial
                    </a>
                  </div>
                </div>
                <div className="usecase-block">
                  <div className="usecase-single" id="usecase-sales-team">
                    <img
                      src="/assets/img/sales-team.png"
                      alt="ruttl avatar for sales teams"
                    />
                    <h3>
                      <span className="blue-text">Sales teams</span>
                      &nbsp;— Create custom sales experiences to increase sales and
                      offer a personal touch to your customers
                    </h3>
                    <p>
                      Quickly change text, images, styling and bring your visions to
                      life. ruttl enables the sales team to customise demos before
                      each meeting, which increases the bottom line conversion rate
                    </p>
                    <a className="button" href="https://web.ruttl.com/signup">
                      Start Free Trial
                    </a>
                  </div>
                </div>
                <div className="usecase-block">
                  <div className="usecase-single">
                    <img src="/assets/img/freelancer.png" alt="Freelancer" />
                    <h3>
                      <span className="blue-text">Freelancers</span>
                      &nbsp;— Independent designers, developers, and content
                      creators can use ruttl to perfect their web projects
                    </h3>
                    <p>
                      ruttl has been designed keeping in mind both teams and
                      individual users. Freelance designers, developers, and writers
                      can use ruttl to review, edit, and build websites, web apps,
                      and more.
                    </p>
                    <a className="button" href="https://web.ruttl.com/signup" className="button">
                      Start Free Trial
                    </a>
                  </div>
                </div> */}
                </div>
                <p style={{ fontSize: '18px', fontWeight: '600' }}>
                  To know about the competitive advanatges of ruttl, check out
                  our
                  <Link to="/alternatives/">&nbsp;alternatives&nbsp;</Link>
                  page.
                </p>
              </div>
            </section>
            <NewFaq
              data={[
                {
                  id: 0,
                  uuid: 'a',
                  q: 'Do my clients need to sign up to leave comments?',
                  a: 'Not at all. Your clients can leave comments directly without login. They simply need to enter their name, and the commenting process can be initiated on the webpage/image.',
                },
                {
                  id: 1,
                  uuid: 'b',
                  q: 'Can I review static webpage design images with my team on ruttl?',
                  a: 'Absolutely. Simply upload the image, and your team can instantly start leaving comments. The best part - even clients can leave comments - without the hassles of login!',
                },
                {
                  id: 2,
                  uuid: 'c',
                  q: 'Can my clients use their mobile phone to leave comments on-the-go?',
                  a: 'ruttl is only meant to be used on a laptop/desktop browser, so mobile commenting would not be supported.',
                },
                {
                  id: 3,
                  uuid: 'd',
                  q: 'Can the comments be edited after we add them?',
                  a: ' Yes, all comments can be edited (or even deleted if not required) after they’re added to ruttl, including comments added by your clients or guests.',
                },
                {
                  id: 4,
                  uuid: 'e',
                  q: 'Can clients use the integrations inside ruttl?',
                  a: 'No, integrations will only be supported for ruttl account holders.',
                },
                {
                  id: 5,
                  uuid: 'f',
                  q: 'Will clients be a part of my project member count, if I share the link with them & not add them to my project?',
                  a: 'Absolutely not. Only people added to your ruttl project with an email address will be counted as collaborators.',
                },
                // {
                //   id: 6,
                //   uuid: 'g',
                //   q:
                //     'Can my clients use their mobile phone to leave comments on-the-go?',
                //   a:
                //     'ruttl is only meant to be used on a laptop/desktop browser, so mobile commenting would not be supported.',
                // },
                {
                  id: 6,
                  uuid: 'g',
                  q: 'Can clients approve a webpage or an image?',
                  a: 'No, only project members can approve a webpage or an image. The project owner will have to make project member(s) as approver(s), only they can carry out the functionality of approving.',
                },
              ]}
            />
          </main>
        </>
      )}
    </Layout>
  );
};

CommentMode.propTypes = {
  location: PropTypes.object,
};

export default CommentMode;
